import React from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const GizlilikPolitikasi = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)


  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Gizlilik Politikası ve KVKK Kanunu ' />
      <Divider space='5' />
      <h3 style={{ color: "black", textAlign: "center" }}>Gizlilik Politikası ve KVKK Kanunu</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <p className="gzl-p"><span classname="gzl-p-span"><br />&nbsp;BOXCAD YAZILIM AMBALAJ TASARIM REKLAM IHRACAT I&Ccedil; VE DIS TICARET LIMITED SIRKETI (&quot;Şirket&quot;), internet sitesini ziyaret eden kişilerin paylaşmış olduğu bilgilerin gizliliğini sağlamayı ilke olarak kabul etmiştir. Bu nedenle işbu &quot;Gizlilik Politikası&quot; kişilerin hangi bilgilerinin, hangi yollarla işlendiğini, bu bilgilerin hukuka uygun olarak ve vermiş olduğunuz izin kapsamında hangi &uuml;&ccedil;&uuml;nc&uuml; kişiler ile paylaşıldığını ve Şirket tarafından ne şekilde korunduğunu a&ccedil;ıklayarak sizi bilgilendirmek amacı ile oluşturulmuştur. Ayrıca işbu Gizlilik Politikası &ccedil;er&ccedil;evesinde bu bilgilerin doğruluğunun sizler tarafından nasıl denetlenebileceği ve istendiğinde Şirketin internet sitesine başvurarak bu bilgilerin silinmesini nasıl sağlayabileceğiniz belirtilmiştir. Şirket Tarafından kişilere her t&uuml;rl&uuml; kanaldan sunulan t&uuml;m hizmetler kısaca &quot;Hizmet&quot; olarak anılacaktır.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Veri Sorumlusu</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Kişisel veri kimliği belirli veya belirlenebilir ger&ccedil;ek kişiye ilişkin isim, soyisim, doğum tarihi veya telefon numarası gibi her t&uuml;rl&uuml; bilgiyi ifade etmektedir.</span></p>
        <p className="gzl-p"><span classname="gzl-p-span">Kişisel verileriniz, veri sorumlusu sıfatıyla Konya Ticaret Sicil M&uuml;d&uuml;rl&uuml;ğ&uuml; Ticaret Sicil M&uuml;d&uuml;rl&uuml;ğ&uuml; nezdinde 0463047766500019 sicil numarası ile kayıtlı ve şirket merkezi Fevzi&ccedil;akmak mahallesi 10753.sokak No:30/AK adresinde bulunan BOXCAD YAZILIM AMBALAJ TASARIM REKLAM IHRACAT I&Ccedil; VE DIS TICARET LIMITED SIRKETI (Şirket) tarafından 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) başta olmak &uuml;zere, ilgili mevzuat d&uuml;zenlemeleri ve yetkili otorite kararları/duyuruları ile işbu Gizlilik Politikası &ccedil;er&ccedil;evesinde işlenmektedir.</span></p>
        <p className="gzl-p"><span classname="gzl-p-span">Şirket, gizliliğinizin ve kişisel verilerinizin korunmasına ilişkin endişelerinize saygı duymaktadır. Bu kapsamda Şirket, kişisel verilerinizi KVKK başta olmak &uuml;zere kişisel verilerin korunmasına ilişkin t&uuml;m mevzuat h&uuml;k&uuml;mlerine uygun şekilde işlemekte, verilerinizin g&uuml;venli şekilde barındırılmasını sağlamakta ve olası hukuka aykırı erişimlere karşı gerekli t&uuml;m g&uuml;venlik tedbirlerini almaktadır.</span></p>
        <p className="gzl-p"><span classname="gzl-p-span">İşbu Gizlilik Politikası metni, Şirket tarafından aşağıda belirtilen kanallar vasıtası ile hangi kişisel veri kategorilerinizin toplandığı ve hangi s&uuml;re&ccedil;ler ve ama&ccedil;larla işlendiği, hangi alıcı gruplarına aktarıldığı, kişisel verilerinizin korunmasına ilişkin haklarınızı ve veri sorumlusu olarak Şirketin aydınlatma y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; kapsamında sizleri bilgilendirmesi gereken diğer a&ccedil;ıklamaları i&ccedil;ermektedir.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Gizlilik Politikasının Kapsamı ve Toplanan Veriler</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Belirli bir kişiye ait kullanılacak ve işlenecek bilgiler, yalnızca bu kişinin kendi istemiyle veri girmesi veya bu hususta a&ccedil;ık&ccedil;a muvafakat vermesi halinde m&uuml;mk&uuml;nd&uuml;r. Veri girilmesi veya bu konuda a&ccedil;ık&ccedil;a muvafakat verilmesi, kişinin aşağıda belirtilen şartlar ile mutabık olduğunun g&ouml;stergesidir. İnternet sitesi ziyaret edildiğinde bazı bilgiler internet sitesi sunucularında depolanır.</span></p>
        <p className="gzl-p"><span classname="gzl-p-span">Bu veriler şu şekilde olabilir:</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- İsim</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Soyisim</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Adres</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Posta kodu</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- E-mail</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Telefon/Fax numarası</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Kimlik numarası</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Sigorta numarası</span></p>
        <p className="gzl-p"><span classname="gzl-p-span">Bu veriler doğrultusunda site kullanıcısı hakkında birtakım sonu&ccedil;lar &ccedil;ıkarılır. Ancak bu kişisel veriler sadece anonim olarak kullanılabilir. Bu verilerin dışarıdan bir hizmet sağlayacısına aktarılması durumunda veri g&uuml;venliğine ilişkin mevcut yasal d&uuml;zenlemeler &ccedil;er&ccedil;evesinde aktarılması i&ccedil;in gerekli işlemler ger&ccedil;ekleştirilir.</span></p>
        <p className="gzl-p"><span classname="gzl-p-span">Kendi isteğiniz ile kişisel bilgilerin verilmesi halinde Şirket, bu bilgileri yasada &ouml;ng&ouml;r&uuml;len veya kullanıcının kabul beyanında belirtilen sınırlamalar &ccedil;er&ccedil;evesinde kullanacağını ve bu &ccedil;er&ccedil;evede işleme tabi tutacağını veya aktarılacağını taahh&uuml;t eder.</span></p>
        <p className="gzl-p"><span classname="gzl-p-span"><br />&nbsp;Kişisel verilerin ve &ccedil;erezlerin kullanım ama&ccedil;ları aşağıda listelenmiştir:</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Kullanıcıların deneyimlerini &ouml;zelleştirme</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Web sitelerine erişim</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Kullanıcılarla iletişimin sağlanması</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- Rekabet</span></p>
        <p className="gzl-p-helv"><span classname="gzl-p-span">- İnternet sitesini y&ouml;netme ve kayıt tutma</span></p>
        <p className="gzl-p"><span classname="gzl-p-span">Sunulan hizmetin bir par&ccedil;ası olarak Şirket, hizmet alan kişilere ilişkin bilgileri işbu Gizlilik Politikası kapsamında elde edilebilir ve aktarabilir. Bu t&uuml;r bilgi aktarımları, &uuml;&ccedil;&uuml;nc&uuml; kişiler tarafından belirlenen şartlara ve &uuml;&ccedil;&uuml;nc&uuml; kişiler ile mevcut s&ouml;zleşmelere ve y&uuml;r&uuml;rl&uuml;kteki mevcut yasal mevzuata uygun olarak yapılır. Bu Gizlilik Politikası, bilgi aktarımı yapılan &uuml;&ccedil;&uuml;nc&uuml; kişilerin gizlilik uygulamalarını yansıtmamaktadır ve onların gizlilik politikalarından veya uygulamalarından Şirket sorumlu değildir. İşbu Gizlilik Politikası Şirketin kendi kontrol&uuml; dışındaki uygulamalar tarafından toplanılan bilgiler, &uuml;&ccedil;&uuml;nc&uuml; taraflara ait internet siteleri ve platformlar tarafından toplanılan bilgiler, &uuml;&ccedil;&uuml;nc&uuml; taraflarca Şirket internet sitesindeki bağlantılar &uuml;zerinden toplanan bilgiler veya Şirketin sponsor olduğu veya katıldığı &uuml;&ccedil;&uuml;nc&uuml; taraf internet siteleri &uuml;zerindeki başlıklar, kampanyalar ve diğer reklam veya tanıtımları kapsamamaktadır. &Uuml;&ccedil;&uuml;nc&uuml; tarafların kendi internet siteleri yoluyla topladığı, sakladığı ve kullandığı kişisel verilere y&ouml;nelik yapılan işlemlerden Şirket sorumlu değildir.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;&Ccedil;ocukların Gizliliği</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">İnternet sitemizde 18 yaşının altındaki &ccedil;ocuklardan bilerek veri toplamamaktadır. Eğer 18 yaşının altındaysanız l&uuml;tfen Hizmetleri kullanarak herhangi bir kişisel verinizi paylaşmayınız. Bu noktada &ccedil;ocukların veli veya vasilerinin işbu Gizlilik Politikasının uygulanması konusunda &ccedil;ocuklarını kişisel verilerini paylaşmamaları konusunda eğiterek yardımları beklenmektedir.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;&Ccedil;erez Kullanımı</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Şirket, anılan kişisel verilerden bazılarını teknik bir iletişim dosyasını (&Ccedil;erez-cookie) kullanarak elde edebilir. Bahsi ge&ccedil;en teknik iletişim dosyaları, ana bellekte saklanmak &uuml;zere bir internet sitesinin kullanıcının tarayıcısına g&ouml;nderdiği k&uuml;&ccedil;&uuml;k metin dosyalarıdır. Teknik iletişim dosyası bir internet sitesi hakkında durum ve tercihleri saklayarak İnternet&apos;in kullanımını kolaylaştırır. Teknik iletişim dosyası, internet sitesini ka&ccedil; kişinin kullandığını, bir kişinin internet sitesini hangi ama&ccedil;la, ka&ccedil; kez ziyaret ettiğini ve ne kadar kaldıklarını belirten istatistiksel bilgileri elde etmeye ve kullanıcılar i&ccedil;in &ouml;zel tasarlanmış kullanıcı sayfalarından dinamik olarak reklam ve i&ccedil;erik &uuml;retilmesine yardımcı olur. Teknik iletişim dosyası, ana bellekte veya e-postalardan veri veya başka herhangi bir kişisel veri almak i&ccedil;in tasarlanmamıştır. Tarayıcıların pek &ccedil;oğu başta teknik iletişim dosyasını kabul eder bi&ccedil;imde tasarlanmıştır, fakat kullanıcılar dilerse teknik iletişim dosyasının gelmemesi veya teknik iletişim dosyası g&ouml;nderildiğinde ikaz verilmesini sağlayacak bi&ccedil;imde ayarları değiştirebilirler. Bu ayar değişikliğinden kaynaklanan herhangi bir kusurlu &ccedil;alışma tarafımıza atfedilmemelidir.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Online Alışveriş İ&ccedil;in Gereken Veriler</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Şirket, internet sitesi &uuml;zerinden yaptığınız &uuml;r&uuml;n veya hizmet alışverişlerinde g&uuml;venliğinizi &ouml;nemsemektedir. Bu nedenle kredi kartı bilgileriniz sadece sipariş işlemi ger&ccedil;ekleştirilirken kullanılır ve veri tabanında kayıtlı olarak tutulmaz.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Diğer İnternet Sitelerine Verilen Bağlantılar</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Şirket internet sitesi, i&ccedil;eriğinde &uuml;&ccedil;&uuml;nc&uuml; kişilere ait internet sitelerine y&ouml;nlendiren bağlantılar/linkler i&ccedil;erebilecek olup işbu Gizlilik Politikası bu internet siteleri i&ccedil;in ge&ccedil;erli değildir. Şirket bu internet sitelerine ilişkin herhangi bir sorumluluk kabul etmemektedir.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Sosyal Medya</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Şirket internet sitesi &uuml;retilen hizmetlerin ve &uuml;r&uuml;nlerin sosyal medya ağlarında paylaşılabilmesi i&ccedil;in bazı sosyal medya ağlarına g&ouml;m&uuml;l&uuml; şekilde bağlantı vermektedir. Bu bağlantılı hizmetler yalnızca onay verilmesi durumunda &ccedil;alışmakta olup bu sosyal medya bağlantılarının amacı site i&ccedil;eriklerinin g&ouml;r&uuml;nt&uuml;lenmesi ve istenildiğinde arkadaşlarınızla ve yakınlarınızla paylaşabilmenizdir. Bu y&ouml;nlendirilen sosyal medya ağlarıyla ilgili veri paylaşımı onların sitelerinde ilan ettikleri gizlilik politikalarına tabidir. Bu gizlilik politikalarını da incelemeniz &ouml;nem taşımaktadır.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Bilgi G&uuml;ncelleme ve Değişiklik</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Şirket, gizlilik veri koruma prensiplerini g&uuml;ncel tutmak ve ilgili mevzuata uygun hale getirmek i&ccedil;in işbu Gizlilik Politikası&apos;nın i&ccedil;eriğini dilediği zaman değiştirebilir. Değişen Gizlilik Politikası Şirket internet sitesinde ilan edilir. Gizlilik Politikası&apos;nın g&uuml;ncel haline www.kututasarimi.com adresinden s&uuml;rekli olarak ulaşmanız m&uuml;mk&uuml;nd&uuml;r. İşbu Gizlilik Politikası&apos;nın değiştirilmesinden sonra Şirketin hizmet veya uygulamalarını kullanmaya devam etmeniz halinde yapılan değişiklikleri kabul ettiğiniz varsayılır. Şirketin değişiklik yaptığı Gizlilik Politikası h&uuml;k&uuml;mleri internet sitesinde yayınlandığı tarihte y&uuml;r&uuml;rl&uuml;k kazanır.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;İşlenen Kişisel Veri Kategorileri ve Kişisel Verilerin İşlenme Ama&ccedil;ları</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Paylaşılan kişisel veriler m&uuml;şterilere verilen hizmetlerin gereklerini, s&ouml;zleşmenin ve teknolojinin gereklerine uygun şekilde yapabilmek, sunulan &uuml;r&uuml;n ve hizmetlerimizi geliştirebilmek i&ccedil;in; kamu g&uuml;venliğine ilişkin hususlarda ve hukuki uyuşmazlıklarda, talep halinde ve mevzuat gereği savcılıklara, mahkemelere ve ilgili kamu g&ouml;revlilerine bilgi verebilmek i&ccedil;in; &uuml;yelerimize geniş kapsamda &ccedil;eşitli imkanlar sunabilmek i&ccedil;in veya bu imkanları sunabilecek kişi ve kurumlarla yasal &ccedil;er&ccedil;evede paylaşabilmek i&ccedil;in; reklam tercihlerini analiz etmek i&ccedil;in 6698 sayılı KVKK ve ilgili ikincil d&uuml;zenlemelere uygun olarak işlenecektir.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Verileri Saklama S&uuml;resi</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">&Uuml;ye olurken ya da &uuml;r&uuml;n veya hizmet satın alırken doğrudan paylaşılan veriler &uuml;yelik s&uuml;resinin ya da satış işlemlerinin devamı boyunca saklanmaktadır. Kampanyalardan haberdar olmak i&ccedil;in abone olunan b&uuml;ltenlere abonelik devam ettik&ccedil;e paylaşılan veriler saklanmaktadır.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Kişisel Verilerin Ne Şekilde İşlenebileceği</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">6698 sayılı Kişisel Verilerin Koruması Kanunu (KVKK) uyarınca, BOXCAD YAZILIM AMBALAJ TASARIM REKLAM IHRACAT I&Ccedil; VE DIS TICARET LIMITED SIRKETI ile paylaşılan kişisel veriler, tamamen veya kısmen, otomatik olarak veya herhangi bir veri kayıt sisteminin par&ccedil;ası olmak kaydıyla otomatik olmayan yollarla elde edilerek, kaydedilerek, depolanarak, değiştirilerek, yeniden d&uuml;zenlenerek kısacası veriler &uuml;zerinde ger&ccedil;ekleştirilen her t&uuml;rl&uuml; işleme konu olarak tarafımızca işlenebilecektir. KVKK kapsamında veriler &uuml;zerinde ger&ccedil;ekleştirilen her t&uuml;rl&uuml; işlem &quot;kişisel verilerin işlenmesi&quot; olarak kabul edilmektedir.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Kişisel Verilerin Aktarılabileceği &Uuml;&ccedil;&uuml;nc&uuml; Kişi Veya Kuruluşlar Hakkında Bilgilendirme</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Yukarıda belirtilen ama&ccedil;larla, BOXCAD YAZILIM AMBALAJ TASARIM REKLAM IHRACAT I&Ccedil; VE DIS TICARET LIMITED SIRKETI ile paylaşılan kişisel verilerin aktarılabileceği kişi ve kuruluşlar; ana hissedarlar, hissedarlar, reklam verenler, doğrudan veya dolaylı yurti&ccedil;i/ yurtdışı iştiraklerimiz; başta BOXCAD YAZILIM AMBALAJ TASARIM REKLAM IHRACAT I&Ccedil; VE DIS TICARET LIMITED SIRKETI altyapısını kullanan &uuml;ye firmalar ve bunlarla sınırlı olmamak &uuml;zere sunulan hizmet ile ilgili kişi ve kuruluşlar olmak &uuml;zere, faaliyetlerimizi y&uuml;r&uuml;tmek &uuml;zere veya Veri İşleyen sıfatı ile hizmet aldığımız, iş birliği yaptığımız, program ortağı kuruluşları, yurti&ccedil;i/yurtdışı kuruluşlar ve diğer 3. kişiler ve kuruluşlardır. Mahkemelerden gelen bağlayıcı taleplere y&ouml;nelik olarak hukuki y&uuml;k&uuml;ml&uuml;l&uuml;klerimiz dahilinde verileriniz istisnai olarak resmi kurumlarla paylaşılabilecektir.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;KVKK Y&uuml;r&uuml;rl&uuml;ğe Girmeden &Ouml;nce Elde Edilen Kişisel Veriler</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">BOXCAD YAZILIM AMBALAJ TASARIM REKLAM IHRACAT I&Ccedil; VE DIS TICARET LIMITED SIRKETI internet sitesinde KVKK&apos;nın y&uuml;r&uuml;rl&uuml;k tarihi olan 07.04.2016 tarihinden &ouml;nce depolanan kişisel veri bulunmamaktadır.</span></p>
        <p className="gzl-p"><strong><span classname="gzl-p-span"><br />&nbsp;Veri Sahibinin Hakları</span></strong></p>
        <p className="gzl-p"><span classname="gzl-p-span">Veri sahibi verilerine erişim hakkını her zaman kullanabilir. Buna ek olarak ilgili şartların yerine getirilmesi durumunda KVKK madde 11&apos;de d&uuml;zenlenen haklar ve aşağıda belirtilen haklar kullanılabilir.</span></p>
        <ul classname="gzl-ul" type="disc">
          <li className="gzl-li"><span classname="gzl-li-span">D&uuml;zeltme hakkı,</span></li>
          <li className="gzl-li"><span classname="gzl-li-span">Silme hakkı,</span></li>
          <li className="gzl-li"><span classname="gzl-li-span">İşlemeyi kısıtlama hakkı,</span></li>
          <li className="gzl-li"><span classname="gzl-li-span">Yetkili veri koruma denetim kurumu &uuml;zerinden bir şikayet oluşturma hakkı,</span></li>
          <li className="gzl-li"><span classname="gzl-li-span">Veri taşınabilirliği hakkı.</span></li>
        </ul>
        <p className="gzl-p"><span classname="gzl-p-span">Kişisel verilerin, Şirketin kanuni bir faydaya sahip olduğu zeminde işlenmesine ilişkin faaliyetler s&ouml;z konusu olduğunda, i&ccedil;inde bulunulan &ouml;zel durum sonucunda oluşan gerek&ccedil;eler dolayısıyla, veri sahibi kişisel verilerin işlenmesine itiraz hakkına sahiptir. Şirket, verilerin işlenmesine ilişikin olarak koruması gereken &ccedil;ıkarların, hakların ve &ouml;zg&uuml;rl&uuml;klerin &uuml;zerinde yer alan &ouml;nemli bir gerek&ccedil;e olduğunu kanıtlayamadığı veya bu işlem kanuni hak taleplerde bulunmak, bu talepleri kullanmak veya savunmak gibi ama&ccedil;lara hizmet ettiği s&uuml;rece Şirket verilerin işlenmesini durduracaktır.</span></p>
        <p className="gzl-p"><span classname="gzl-p-span">Kişisel verilerin işlenmesine onay verilmesi halinde onayın geri &ccedil;ekilmesi imkanı vardır.</span></p>
        <p className="gzl-p"><span classname="gzl-p-span">Kanun kapsamında, kişisel verileriniz ile ilgili her t&uuml;rl&uuml; talep, şikayet ve &ouml;nerilerinizi, kimliğinizi tevsik edici belgeler ve talebinizi i&ccedil;eren dilek&ccedil;eniz ile aşağıdaki adresimize ulaştırabilir veya g&uuml;venli elektronik imza kullanmak suretiyle Şirketimizin bilgi@kututasarimi.com kayıtlı elektronik posta adresine g&ouml;nderebilirsiniz.</span></p>
      </div>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageGizlilikPolitikasiContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default GizlilikPolitikasi
